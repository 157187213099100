import * as React from 'react'
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, EffectFade, Autoplay} from "swiper";
import {GatsbyImage} from "gatsby-plugin-image";
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, Link, useStaticQuery} from "gatsby";
import {gsap} from "gsap";

export default function HeroSwiper () {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]    
                        placeholder: NONE           
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [slides, setSlides] = useState({});
    useEffect(() => {
        setSlides({
            slide: [
                {
                    id: '1',
                    image: 'GIBUS_ZENIT_pergola.jpg',
                    title: 'Pergole',
                    label: 'Pergola è sinonimo di momenti speciali all\'aria aperta, concetto di benessere e piacere sostenibile; la soluzione progettata per il tuo comfort',
                    action: 'scopri',
                    href: 'pergole'
                },
                {
                    id: '2',
                    image: 'GIBUS_TWIST_pergola_bioclimatica.jpg',
                    title: 'Pergola bioclimatica',
                    label: 'Le pergole bioclimatiche Gibus dialogano silenziosamente con la natura per offrirvi un benessere costante al mutare delle condizioni atmosferiche con il minimo dispendio di energia.',
                    action: 'scopri',
                    href: 'pergolebioclimatiche'
                },
                {
                    id: '3',
                    image: 'GIBUS_NODO_tenda_a_bracci.jpg',
                    title: 'Tende da sole',
                    label: 'Le tende da sole Gibus non solo sono pratiche, robuste, assolutamente efficienti, ma ritagliano un angolo ombreggiato in cui godere dell’aria e della luce anche quando il sole è a picco.',
                    action: 'scopri',
                    href: 'tendedasole'
                },
                {
                    id: '5',
                    image: 'SIMTA_tessuti_per_rivestimenti.jpg',
                    title: 'Rivestimenti',
                    label: 'Il tempo e l\'usura non sono amici dei tessuti che rivestono i vostri pezzi di arredo, ma affidandovi ai nostri artigiani potrete restaurarli o ripararli e donare loro nuova vita.',
                    action: 'scopri',
                    href: 'rivestimenti'
                },
                {
                    id: '6',
                    image: 'Gamma_tessuti_da_interno.jpg',
                    title: 'Complementi d’arredo',
                    label: 'I complementi d’arredo sono immancabili e irrinunciabili per donare personalità agli ambienti in cui vivi: creano stile e continuità cromatica nel progetto d’arredamento.',
                    action: 'scopri',
                    href: 'arredamentointerni'
                },
                {
                    id: '7',
                    image: 'GIBUS_VELVET_PLUS_4.jpg',
                    title: 'Pergole con lame impacchettabili',
                    label: ' ',
                    action: 'scopri',
                    href: 'pergolebioclimatiche'
                },
            ],
        })
    }, [gContext])
    return (
        <Swiper
            effect={"fade"}
            navigation
            loop
            autoplay={{
                delay: 9000,
                disableOnInteraction: false,
            }}
            modules={[Navigation, EffectFade, Autoplay]}
            spaceBetween={0}
            slidesPerView={1}
            className="h-[54vh] sm:h-[72vh] lg:h-[730px]"
            onSlideChangeTransitionStart={
                (swiper) => {
                    const current = swiper.slides[swiper.activeIndex]
                    const content = current.getElementsByClassName('swiper-content')[0]
                    const image = current.getElementsByClassName('swiper-image')[0]
                    gsap.from(content, {duration: 0.8, delay: 0.5, autoAlpha: 0, y: "-80"})
                    gsap.from(image, {duration: 0.5, delay: 0.1, autoAlpha: 0})
                }
            }
        >
            {(slides?.slide || []).map((item) => (
                <SwiperSlide key={item.id}>
                    <div className="relative h-full w-full overflow-hidden">
                        <div className="absolute inset-0">
                            <GatsbyImage image={gContext.getImageFromName(images, item.image)}
                                         alt={item.image}
                                         loading="eager"
                                         objectFit="cover"
                                         objectPosition="50% 50%"
                                         className="swiper-image h-full w-full object-cover"/>
                        </div>
                        <div className="relative max-w-[80%] sm:max-w-[1400px] h-full sm:mx-auto sm:pl-10 pr-10 flex">
                            <div className="flex items-center h-full py-16 px-4 sm:px-6 lg:px-8 bg-black/70">
                                <div className="swiper-content flex flex-col justify-center text-white w-full max-w-[370px] space-y-4">
                                    <h2 className="font-bold uppercase text-2xl sm:text-3xl md:text-4xl leading-none">{gContext.t(item.title)}</h2>
                                    {item.label ? (
                                        <p className="text-justify">{gContext.t(item.label)}</p>
                                    ) : ('')}
                                    {item.action ? (
                                        <>
                                            {item.href ? (
                                                <Link to={gContext.url(item.href)} className="primary-2-button self-center max-w-[160px] w-full">
                                                    {gContext.t(item.action)}
                                                </Link>
                                                ) : (
                                                <button type="button" className="primary-2-button self-center max-w-[160px] w-full">{gContext.t(item.action)}</button>
                                            )}
                                        </>
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
